@import "src/styles/main";

.landing-page {
  padding-top: 70px;

  @include md {
    padding-top: 0;
  }

  &__wrapper {
    margin: 32px auto !important;
    display: flex;
    align-items: center;
    flex-direction: column-reverse;

    @include lg {
      flex-direction: row;
      margin: 64px auto !important;
    }
  }

  &__form {
    padding: 0 32px;
    text-align: center;

    @include lg {
      padding: 0 48px 0 32px;
      text-align: left;
    }

    h1 {
      font-size: 33px;
      font-weight: 600;
      letter-spacing: -0.3px;
      line-height: 120%;
      margin: 0;

      @include md {
        font-size: 55px;
      }
    }

    p {
      font-size: 12px;
      letter-spacing: -0.3px;
      line-height: 160%;
      margin: 8px 0 20px 0;
      padding: 0;

      @include md {
        font-size: 20px;
        margin: 12px 0 22px 0;
      }
    }
  }

  &__input-wrapper {
    display: flex;
    justify-content: center;

    @include lg {
      justify-content: flex-start;
    }

    input {
      background-color: $white;
      border: 1px solid $silver;
      border-radius: 8px;
      flex: 1;
      font-size: 10px;
      font-weight: 400;
      height: 35px;
      outline: none;
      color: $black;
      padding-right: 14px;
      padding-left: 14px;
      max-width: 300px;

      @include md {
        font-size: 16px;
        height: 43px;
      }

      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: rgba(24, 24, 12, 0.5);
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: rgba(24, 24, 12, 0.5);
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: rgba(24, 24, 12, 0.5);
      }
    }

    button {
      align-items: center;
      background-color: $blue;
      border-radius: 8px;
      border: none;
      color: $white;
      cursor: pointer;
      display: flex;
      font-size: 10px;
      font-weight: 600;
      height: 35px;
      outline: none;
      padding: 0 20px;
      margin-left: -10px;

      @include md {
        font-size: 16px;
        height: 43px;
      }
    }
  }

  &__photo {
    img {
      max-width: 285px;
      width: 100%;

      @include md {
        max-width: none;
        width: 500px;
      }
    }
  }
}
