// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;

// Small devices
@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

// Fonts
$rubik: "Rubik", sans-serif;
$roboto: "Roboto", sans-serif;
$poppins: "Poppins", sans-serif;

// Colors
$gray: #f5f5f7;
$green: #0fac4f;
$green-light: #00ff75;
$black: #18180c;
$white: #ffffff;

// new colors
$blue: #455ae6;
$purple: #8797ff;
$blue-light: #dbe0ff;
$silver: #d2d3db;
$blue-dark: #3b3f57;
