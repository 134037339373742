@import "src/styles/main";

.privacy-policy {
  h1 {
    font-size: 2em;
    margin: 0.3em 0;
    line-height: 1;
  }
  h2 {
    font-size: 1.6em;
    margin: 0.1em 0;
    line-height: 1;
  }
}

.modal {
  &__content {
    padding: 8px 16px;
  }

  &__close-button {
    position: absolute;
    right: 24px;
    top: 24px;
    cursor: pointer;

    svg {
      width: 14px;
      height: 14px;
    }
  }
}
