@import "src/styles/main";

canvas {
  display: none;
}

.polling-welcome {
  background-position: bottom center;
  background-size: cover;
  color: white;
  height: 100vh;
  word-break: keep-all;

  @media (max-width: $screen-lg-min) {
    height: 100%;
  }

  &__content {
    padding: 82px 32px !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
  }

  h1 {
    font-size: 40px;
    font-weight: 700;
    letter-spacing: -0.3px;
    line-height: 102%;
    margin: 0;
    max-width: 250px;

    &.polling-welcome__h1--margin {
      margin-bottom: 24px;
    }

    @include sm {
      font-size: 55px;
      line-height: 120%;
      max-width: 300px;
    }
  }

  h3 {
    font-size: 13px;
    line-height: 160%;
    letter-spacing: -0.3px;
    margin: 8px 0 24px 0;
    max-width: 250px;
    font-weight: 500;

    @include sm {
      font-size: 20px;
      max-width: 400px;
      margin: 12px 0 32px 0;
    }
  }
}

.polling {
  height: 100vh;
  word-break: keep-all;

  @media (max-width: $screen-lg-min) {
    height: 100%;
  }

  @include sm {
    padding: 40px 0 64px 0;
  }

  &__content {
    margin: 0 auto;
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include sm() {
      max-width: $screen-sm-min;
    }
  }

  &__steps {
    border-radius: 6px;
    box-shadow: 12px 12px 40px rgba(0, 0, 0, 0.1);
    padding: 24px 32px 18px 32px;

    @include sm() {
      box-shadow: none;
    }
  }

  &__step {
    margin-top: 20px;
    flex: 1;
    overflow-y: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    position: relative;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__footer {
    align-items: center;
    display: flex;
    justify-content: space-between;

    &-button {
      display: flex;
      align-items: center;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.5px;
      padding: 24px 0;
      cursor: pointer;
      background-color: transparent;
      border: none;

      span {
        margin: 0 8px;
        color: $black;
      }

      svg path {
        fill: $blue;
      }

      &--hidden {
        opacity: 0;
      }

      &--disabled {
        span {
          opacity: 0.5;
        }

        svg path {
          fill: $blue-light;
        }
      }

      &:first-child svg {
        transform: rotate(180deg);
      }
    }

    &--with-privacy-btn {
      margin-bottom: 40px;

      @include sm {
        margin-bottom: 0;
      }
    }
  }
}
