@import "src/styles/main";

$sidebar-max-width: 274px;

.webinar {
  margin: 0 auto;
  max-width: $screen-xl-min;
  padding: 52px 0 15px 0;
  position: relative;
  height: 100%;

  @include lg {
    padding: 32px 0;
    height: 100vh;
  }

  &__container {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 1000px;

    @include lg {
      flex-direction: row;
    }
  }

  &__video {
    align-items: center;
    display: flex;

    @include lg() {
      flex: 1;
      flex-direction: column;
    }

    &-header {
      justify-content: center;
      background-color: $gray;
      border-radius: 14px;
      display: none;
      height: 80px;
      flex-direction: column;
      margin-bottom: 16px;
      width: 100%;
      padding: 19px 26px;

      h2 {
        font-weight: 600;
        line-height: 19px;
        font-size: 16px;
        margin: 0;
        padding: 0;
      }

      span {
        font-size: 13px;
        line-height: 15px;
        margin-top: 6px;
        display: flex;
        align-items: center;

        img {
          margin-right: 8px;
          height: 20px;
          width: 20px;
        }
      }

      @include lg {
        display: flex;
      }
    }

    &-start {
      bottom: 0;
      height: 100%;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        border-radius: 8px;
        display: flex;
        align-items: center;
        height: 40px;
        background-color: $blue;
        font-weight: 600;
        padding: 0 20px;
        color: $white;
        font-size: 13px;
        cursor: pointer;
        z-index: 2;
      }

      &--hide {
        span {
          display: none;
        }
      }
    }

    &-wrapper {
      cursor: pointer;
      max-height: 486px;
      width: 100%;
      overflow: hidden;
      position: relative;
      padding-top: 56.25%;
      border-radius: 14px;

      iframe {
        width: 100%;
        height: 100%;
      }
    }

    &-player {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__sidebar {
    background-color: $gray;
    border-radius: 14px;
    width: 100%;
    margin-top: 15px;
    min-height: 200px;
    flex: 1;

    @include lg {
      display: flex;
      flex-direction: column;
      margin-left: 15px;
      margin-top: 0;
      max-width: $sidebar-max-width;
    }

    &-empty-state {
      color: #868686;
      font-size: 13px;
      margin-top: 4px;
    }
  }

  &__timecode {
    display: none;
    flex-direction: column;
    height: 80px;
    align-items: center;
    justify-content: center;
    background-color: $blue;
    border-radius: 8px 8px 0 0;
    padding-top: 8px;

    span {
      color: $white;
      font-size: 13px;
      margin-top: 4px;
      font-weight: 700;
    }

    @include lg {
      display: flex;
    }
  }

  &__chat {
    margin: 15px 10px 10px 10px;
    flex: 1;
    overflow: hidden;
    height: calc(100% - 32px);
    background-color: $white;
    padding: 15px 15px 32px 15px;
    border-radius: 8px;
    position: relative;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }

    &--mobile-hide {
      display: none;

      @include lg {
        padding: 12px 12px 40px 12px;
        display: block;
      }
    }

    &-list {
      overflow-y: scroll;
      height: 100%;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }
    }

    @include lg {
      height: auto;
    }

    &-name,
    &-message {
      font-size: 13px;
      line-height: 160%;
      letter-spacing: -0.3px;
    }

    &-name {
      font-weight: 600;
      margin-bottom: 4px;
    }

    &-message {
      font-weight: 400;
    }

    &-item {
      margin-bottom: 15px;
    }

    &-input {
      background-color: $white;
      border: none;
      color: $black;
      flex: 1;
      font-size: 13px;
      font-weight: 400;
      height: 36px;
      line-height: 160%;
      margin-right: 8px;
      outline: none;

      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: rgba(0, 0, 0, 0.5);
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: rgba(0, 0, 0, 0.5);
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: rgba(0, 0, 0, 0.5);
      }

      &-wrapper {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        border-top: 1px solid #e0e0e0;
        padding: 0 15px;

        img {
          width: 14px;
          height: 14px;
        }

        button {
          background: none;
          border: none;
          cursor: pointer;
          line-height: 0;
          margin: 0;
          outline: none;
          padding: 0;
        }

        @include lg {
          padding: 0 12px;
        }
      }
    }
  }

  &__poll {
    background-color: $white;
    padding: 15px 15px 0 15px;
    margin: 20px 10px 20px 10px;
    border-radius: 8px;

    @include lg {
      padding: 12px 12px 0 12px;
      margin: 0 10px 20px 10px;
    }

    &-title {
      font-weight: 700;
      font-size: 16px;
    }

    &-question {
      margin-top: 8px;
      font-size: 13px;
      line-height: 160%;
      width: 70%;
      letter-spacing: -0.3px;

      @include lg {
        width: 100%;
      }
    }

    &-hr {
      width: 70%;
      height: 1px;
      margin: 8px 0;
      background-color: #e0e0e0;

      @include lg {
        width: 100%;
      }
    }

    &-options {
      display: grid;
      grid-template-columns: 1fr 1fr;

      @include lg {
        grid-template-columns: 1fr;
      }
    }

    &-option {
      cursor: pointer;
      font-size: 13px;
      line-height: 120%;
      margin-bottom: 12px;
      margin-top: 12px;
      display: flex;
      align-items: center;
      padding-right: 4px;
      letter-spacing: -0.3px;

      @include lg {
        margin-bottom: 6px;
        margin-top: 6px;
      }

      &:before {
        border-radius: 50%;
        content: "";
        display: inline-block;
        height: 22px;
        margin-right: 11px;
        min-width: 22px;
        width: 22px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      }

      &--selected {
        &.webinar__poll-option:before {
          background-color: $blue;
        }
      }
    }

    &-button {
      align-items: center;
      background-color: $blue;
      border-radius: 0 0 8px 8px;
      color: $white;
      cursor: pointer;
      display: flex;
      font-size: 13px;
      font-weight: 600;
      height: 40px;
      justify-content: center;
      line-height: 15px;
      margin: 15px -12px 0 -12px;

      &--disabled {
        background-color: $purple;
        cursor: not-allowed;
      }
    }

    &-success-message {
      font-size: 13px;
      line-height: 15px;
      display: flex;
      align-items: center;
      padding-bottom: 24px;

      svg {
        margin-right: 11px;
      }
    }
  }

  &__mobile-bar {
    height: 38px;
    background-color: $blue-light;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    &-item {
      display: flex;
      align-items: center;

      &:last-child {
        margin-left: 32px;

        svg {
          width: 15px;
          height: 15px;
          margin-right: 6px;

          & > * {
            stroke: $black;
          }
        }
      }

      svg,
      img {
        margin-right: 6px;
      }

      img {
        height: 20px;
        width: 20px;
      }
    }

    @include lg {
      display: none;
    }
  }
}

.IIV::-webkit-media-controls-play-button,
.IIV::-webkit-media-controls-start-playback-button {
  opacity: 0;
  pointer-events: none;
  width: 5px;
}
