@import "src/styles/main";

$element-map-width: 183px;
$element-map-width--small: 114px;
$element-map-height: 239px;
$element-map-height--small: 149px;

.home {
  max-width: 600px;
  margin: 0 auto;
  padding: 92px 15px 15px;

  @include md {
    padding: 0 15px 40px 15px;
  }

  &__header {
    padding: 25px 0;
    display: flex;

    @include sm {
      padding: 15px 0;
    }

    h1 {
      font-size: 33px;
      font-weight: 600;
      margin: 0;
      letter-spacing: -0.3px;
      line-height: 120%;
      width: 85%;

      @include sm {
        font-size: 55px;
      }
    }

    img {
      position: absolute;
      right: 0;
      top: 0;
      width: $element-map-width--small;
      height: $element-map-height--small;
      z-index: -1;

      @include sm {
        height: $element-map-height;
        width: $element-map-width;
      }
    }

    &-wrapper {
      position: relative;
    }
  }

  &__session-date {
    font-size: 13px;
    line-height: 160%;
    letter-spacing: -0.3px;

    @include sm {
      font-size: 20px;
    }
  }

  &__enter {
    border-radius: 10px;
    padding: 8px 0;
    border: 1px solid $silver;

    &-container {
      display: flex;
      align-items: center;
    }

    &-image {
      border-radius: 12px;
      height: 64px;
      width: 64px;
      margin-right: 15px;
    }

    &-info {
      flex: 1;

      &-name {
        font-weight: 600;
        line-height: 19px;
        //margin-bottom: 8px;
      }

      &-desc {
        line-height: 15px;
        font-size: 11px;

        @include md {
          font-size: 13px;
        }
      }
    }

    &-button {
      border-radius: 8px;
      display: flex;
      align-items: center;
      height: 40px;
      background-color: $blue;
      font-weight: 600;
      padding: 0 20px;
      color: $white;
      font-size: 11px;
      cursor: pointer;

      @include sm {
        font-size: 16px;
        padding: 0 32px;
      }

      &--disabled {
        background-color: $purple;
        cursor: not-allowed;
      }
    }
  }

  &__reply-title {
    display: flex;
    align-items: center;

    svg {
      margin-left: 8px;
    }
  }

  &__timer {
    align-items: center;
    background-color: $blue;
    border-radius: 14px;
    color: $white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 15px 0;

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 8px;
    }

    &-item--colon {
      padding: 0 2px !important;
      margin-left: -2px;

      .home__timer-number {
        margin-bottom: 18px;
        width: auto;
      }
    }

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 8px;
    }

    &-number {
      font-size: 36px;
      font-weight: 600;
      line-height: 43px;
      margin-bottom: 2px;
      width: 50px;
    }

    span {
      line-height: 16px;
      padding-right: 3px;
      font-size: 14px;
    }
  }

  &__input {
    flex: 1;
    margin-right: 8px;
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 13px;
    line-height: 15px;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: rgba(24, 24, 12, 0.5);
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: rgba(24, 24, 12, 0.5);
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: rgba(24, 24, 12, 0.5);
    }

    &-wrapper {
      border-radius: 10px;
      height: 43px;
      display: flex;
      align-items: center;
      border: 1px solid $silver;
    }
  }
}
