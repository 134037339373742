@import "src/styles/main";

.success {
  padding-top: 78px;
  text-align: center;

  h1 {
    color: $black;
    font-size: 25px;
    font-weight: 600;
    letter-spacing: -0.3px;
    line-height: 112%;
    margin-top: 24px;
  }

  .button {
    margin: 40px auto 0 auto;
  }
}
