@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");
@import "src/styles/main";

body {
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  line-height: 160%;

  @include md {
    font-size: 16px;
  }
}

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

p {
  font-size: 13px;
  letter-spacing: -0.3px;
  line-height: 160%;
  margin: 0;

  @include sm {
    font-size: 16px;
  }
}

.container {
  padding: 0 15px;

  @include sm {
    padding: 0 32px;
  }
}

.container-l {
  padding: 0 32px;
}

.container-mobile {
  padding: 0 15px;

  @include sm {
    padding: 0;
  }
}

.container-fluid {
  max-width: 1124px;
  padding: 0 15px;
  margin: 0 auto;

  @include sm {
    padding: 0 32px;
  }
}

.mb {
  margin-bottom: 15px;

  &0 {
    margin-bottom: 8px;
  }

  &2 {
    margin-bottom: 32px;
  }
}

.loader-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
}

@media (max-width: $screen-lg-min) {
  html,
  body,
  .app,
  #root {
    height: 100%;
  }
}

.button {
  align-items: center;
  background-color: $blue;
  border-radius: 8px;
  border: none;
  color: $white;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  height: 35px;
  outline: none;
  padding: 0 24px;
  width: max-content;
  text-decoration: none;

  @include md {
    font-size: 16px;
    height: 43px;
  }
}
