@import "src/styles/main";

.steps {
  display: flex;
  align-items: center;
  padding: 0 1px;

  &__item {
    flex: 1;
    height: 12px;
    background-color: $blue-light;
    border-radius: 100px;
    position: relative;
    margin: 0 -1px;
    transition: background-color 150ms ease-out;

    &-arrow {
      height: 16px;
      left: -6px;
      position: absolute;
      top: -2px;
      width: 16px;
    }

    &:first-child .steps__item-arrow {
      display: none;
    }

    &--active {
      background-color: $blue;
    }

    @include sm() {
      height: 18px;

      &-arrow {
        height: 22px;
        left: -11px;
        position: absolute;
        top: -2px;
        width: 24px;
      }
    }
  }
}
