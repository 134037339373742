@import "src/styles/main";

.question {
  h3 {
    border-bottom: 1.5px solid #e0e0e0;
    color: $black;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 18px;
    margin: 0;
    max-width: 200px;
    padding-bottom: 8px;
    width: 100%;
    margin-top: 10px;

    @include sm {
      font-size: 16px;
      line-height: 18px;
    }
  }

  p {
    color: $black;
    font-size: 25px;
    font-weight: 600;
    letter-spacing: -0.3px;
    line-height: 112%;
    margin-top: 24px;
    margin-bottom: 32px;
  }

  &__answer {
    align-items: center;
    border-radius: 6px;
    border: 1px solid $blue;
    display: flex;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));
    font-size: 13px;
    line-height: 120%;
    margin-top: 10px;
    padding: 0 16px;
    height: 37px;
    max-width: 264px;
    cursor: pointer;

    &--selected {
      background-color: $blue;
      color: $white;
    }
  }

  &__open-answer {
    flex: 1;

    input {
      align-items: center;
      border-radius: 6px;
      border: 1px solid $blue;
      display: flex;
      filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));
      font-size: 13px;
      line-height: 120%;
      margin-top: 10px;
      padding: 0 16px;
      height: 37px;
      max-width: 264px;
      width: 100%;

      &:focus {
        outline: none;
      }
    }
  }

  &__dropdown {
    position: relative;

    &-button {
      align-items: center;
      border-radius: 6px;
      border: 1px solid $blue;
      cursor: pointer;
      display: flex;
      filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));
      font-size: 13px;
      height: 37px;
      justify-content: space-between;
      line-height: 120%;
      margin-top: 10px;
      max-width: 264px;
      padding: 0 16px;

      svg {
        width: 10px;
        fill: #3d4eb7;
      }

      &--open {
        svg {
          transform: rotate(180deg);
        }
      }
    }

    &-list {
      border-radius: 6px;
      border: 1px solid $blue;
      display: none;
      filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));
      flex-direction: column;
      left: 0;
      max-width: 264px;
      position: absolute;
      top: 40px;
      width: 100%;
      overflow-y: auto;

      &--open {
        display: flex;
      }

      .question__answer {
        border-radius: 0;
        margin: 0;
        border: 0;
        min-height: 37px;

        &:hover:not(.question__answer--selected) {
          background-color: rgba($blue, 0.1);
        }
      }
    }
  }

  .polling__footer {
    position: absolute;
    bottom: 80px;
    right: 0;
    left: 0;

    @include sm {
      bottom: 0;
    }
  }
}
