@import "src/styles/main";

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);

  @include md {
    position: static;
    box-shadow: none;
  }

  &__container {
    align-items: center;
    display: flex;
    height: 70px;
    justify-content: space-between;
  }

  &__logo {
    height: 23px;
    width: auto;

    &-link {
      line-height: 1;
    }
  }

  &__toggler {
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
  }

  &__collapse {
    display: none;
    flex: 1;

    &--open {
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 70px;

      @include md {
        position: static;
      }
    }
  }

  &__nav {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    flex-direction: column;
    background-color: $white;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
  }

  &__item {
    margin: 10px 0 10px 15px;

    @include sm {
      margin: 10px 0 10px 32px;
    }

    a {
      font-weight: 400;
      color: $black;
      text-decoration: none;
      display: flex;
    }

    &--active {
      a {
        font-weight: 600;
      }
    }
  }
}

@include md {
  .navbar {
    &__container {
      height: 130px;
      justify-content: flex-start;
    }

    &__collapse {
      display: block !important;
    }

    &__logo {
      height: 30px;
      width: auto;
    }

    &__toggler {
      display: none;
    }

    &__nav {
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      box-shadow: none;
    }

    &__item {
      margin: 0 0 0 24px;
    }
  }
}
